<template>
    <component :is="sectionComponent" padding-top="10px" padding-bottom="10px">
        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            paysafecard classic <br />
            Amount: {{ value }} <br /><br />

            PIN-CODE: {{ code }} <br />
            Serialnumber: {{ serial }} <br /><br />
            <p>
                <strong>pay online</strong>
            </p>
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            <ul>
                <li>Select paysafecard when paying in an online shop</li>
                <li>Simply enter the 16-digit PIN ready!</li>
            </ul>
            <p>
                Service: <a target="_blank" href="https://www.paysafecard.com/help">www.paysafecard.com/help</a><br />
            </p>
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            <p>
                The commercial resale of your paysafecard is forbidden. Please find the general terms and condions on:
                <a target="_blank" href="https://www.paysafecard.com/">www.paysafecard.com</a>
            </p>
            <p>
                18 months aer purchase the monthly maintenance fee of $2.50 CAD will be deducted directly from the
                balance.
            </p>
            <p>
                Warning: Never pay with paysafecard to unlock your computer or to claim an alledged competion prize.<br />
                Find out more:
                <a target="_blank" href="https://www.paysafecard.com/security">www.paysafecard.com/security</a>.
            </p>
            <p>
                paysafecard is a payment method issued and managed by
                <strong>
                    <a target="_blank" href="https://www.paysafecard.com/">www.paysafecard.com</a> Schweiz GmbH
                </strong>
            </p>
        </component>
    </component>
</template>

<script>
export default {
    props: ['isEmail', 'value', 'code', 'serial', 'terminalId', 'traceCode', 'date', 'time', 'transactionId'],

    computed: {
        sectionComponent() {
            return this.isEmail ? 'mj-section' : 'div';
        },

        textComponent() {
            return this.isEmail ? 'mj-text' : 'div';
        },

        hrComponent() {
            return this.isEmail ? 'mj-divider' : 'hr';
        },
    },
};
</script>
